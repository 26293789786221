//Работа с датой
import React, {forwardRef } from 'react'
import DatePicker, { registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ru from 'date-fns/locale/ru';
import order from "../../store/order.store";
import { observer } from "mobx-react-lite";


const VDatePicker = observer(() => {

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="input-group mb-3">
      <span className="input-group-text">Дата отгрузки</span>
      <button type="button" className="btn btn-outline-secondary" style={{margin: '0px'}} onClick={onClick} ref={ref}>
        {value}
      </button>
    </div>
  ));

  registerLocale('ru', ru)//регестрируем ru регион для даты

  //добавить количество дней к дате
  const addDays = (date, addDays) => {
    return date.setDate(date.getDate() + addDays);
  }

  return (
    
    <DatePicker 
        locale="ru" 
        selected={order.date} 
        onChange={(date) => order.setDate(date)} 
        placeholderText='Дата отгрузки'
        // popperClassName="datepicker"
        dateFormat="dd.MM.yyyy"
        minDate={addDays(new Date(), -15)}
        maxDate={addDays(new Date(), 1)}
        showDisabledMonthNavigation //отобразить ограничение по дате
        withPortal // во весь экран
        customInput={<ExampleCustomInput />}
      />
      
  )
})

export default VDatePicker;