import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import authStore from '../../store/auth.store'
import { observer } from 'mobx-react-lite'
import './Navbar.css'

// Верхняя навигационная панель
export const Navbar = observer(() => {
  const navigate = useNavigate()
  const { logout } = authStore

  //деавторизуемся
  const logoutHandler = (event) => {
    event.preventDefault()
    logout()
    // history.push('/')
    navigate('/')
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          ОХК заказы
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Переключатель навигации"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/order">
                Заказы
              </NavLink>
            </li>
            {/* <li className="nav-item">
          <NavLink className="nav-link" to="/create">Создать</NavLink>
        </li> */}
            {/* <li className="nav-item">
          <NavLink className="nav-link" to="/links">Ссылки</NavLink>
        </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/" onClick={logoutHandler}>
                Выйти
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
})
