import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
// import authStore from '../../store/auth.store';
import { observer } from 'mobx-react-lite'

import './AuthPage.css'
import { Loader } from '../../components/Loader/Loader'
import { useFetching } from '../../hooks/useFetching'
import OhkService from '../../API/OhkService'

export const AuthPage = observer(() => {
  // const {login} = authStore
  const { loading, fetching } = useFetching() //хук обертка для работы с сервером
  const [form, setForm] = useState({
    email: '',
    password: '',
  })

  //Вызывается только после первой отрисовки
  useEffect(() => {
    //делаем активными импуты
    // window.M.updateTextFields()
  }, [])

  //при изменении данных в форме
  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  //вход
  const loginHandler = () => {
    fetching(async () => {
      const data = await OhkService.authorization({ ...form })
      toast(`Добро пожаловать ${data.user}`, {
        icon: '👏',
      })

      // //записываем данные об id и token пользователя
      // login(data.token, data.userId, data.expiresIn * 1000 + Date.now())
    })
  }

  return (
    <div className="container position-absolute top-50 start-50 translate-middle">
      <div className="row justify-content-sm-center">
        <div className="col col-lg-4 col-md-6 col-sm-8">
          {loading ? (
            <Loader />
          ) : (
            <div className="input-group">
              <input
                id="password"
                type="password"
                name="password"
                className="form-control"
                placeholder="Пароль"
                aria-label="Пароль"
                aria-describedby="button-addon2"
                value={form.password}
                onChange={changeHandler}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={loginHandler}
                disabled={loading}
              >
                Войти
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
