import React, { useEffect, useState, useCallback } from 'react'
import toast from 'react-hot-toast'
import { Loader } from '../components/Loader/Loader'
import OhkService from '../API/OhkService'
import VDatePicker from '../components/VDatePicker/VDatePicker'
import orderStore from '../store/order.store'
import { observer } from 'mobx-react-lite'
import SelectLineConsignees from '../components/SelectLineConsignees/SelectLineConsignees'
import SelectLineRoute from '../components/SelectLineRoute/SelectLineRoute'
import NomenclatureTable from '../components/NomenclatureTable/NomenclatureTable'
import authStore from '../store/auth.store'
import { useFetching } from '../hooks/useFetching'

export const OrderPage = observer(() => {
  const { date, routeSelect, bakerieSelect, nomenclature } = orderStore
  const { userId } = authStore
  const [orderIsFormeting, setOrderIsFormeting] = useState(false)

  const { loading, fetching } = useFetching() //хук обертка для работы с сервером
  const { loading: loadingRoute, fetching: fetchingRoute } = useFetching()
  const { loading: loadingBakeries, fetching: fetchingBakeries } = useFetching()

  //Запрос грузополучателей
  useEffect(() => {
    fetchingBakeries(async () => {
      const fetched = await OhkService.getBakeries(userId)

      if (fetched && fetched.length > 0) {
        orderStore.setBakeries(fetched)
        orderStore.setbakerieSelect(fetched[0].code)
      }
    })
  }, [fetchingBakeries, userId])

  //Запрос списка времени доставки
  useEffect(() => {
    fetchingRoute(async () => {
      orderStore.setRoutes([])
      orderStore.setRouteSelect('')
      orderStore.setNomenclature([])

      if (bakerieSelect) {
        const fetched = await OhkService.getRoutes(bakerieSelect)

        if (fetched && fetched.length > 0) {
          orderStore.setRoutes(fetched)
          orderStore.setRouteSelect(fetched[0].code)
        }
      }
    })
  }, [fetchingRoute, bakerieSelect])

  //Запрос номенклатуры
  const fetchNomenclature = useCallback(async () => {
    //??? Нужно передать в строку запроса:
    // Дата - строка чч.мм.гг (13.08.2021)
    // Рейс - число (1)
    // Булочная - строка (н002345)

    fetching(async () => {
      orderStore.setNomenclature([])
      if (date && routeSelect && bakerieSelect) {
        const fetched = await OhkService.getOrder(bakerieSelect, routeSelect, orderStore.getDateToString())

        if (fetched.nomenclature && fetched.nomenclature.length > 0) {
          orderStore.setNomenclature(fetched.nomenclature)
        }
      }
    })
  }, [date, routeSelect, bakerieSelect, fetching])

  useEffect(() => {
    fetchNomenclature()
  }, [fetchNomenclature])

  //Формирование заказа
  const formulateOrder = async () => {
    if (date && routeSelect && bakerieSelect && nomenclature && userId) {
      setOrderIsFormeting(true)

      const data = {
        date: orderStore.getDateToString(),
        route: routeSelect,
        consignee: bakerieSelect,
        nomenclature,
        userId: userId,
      }

      toast.promise(
        fetching(() => OhkService.putOrder(data), false),
        {
          loading: 'Формируем...',
          success: (data) => {
            fetchNomenclature()
            return `Заказ для ${data.consignee} на ${data.date} сформирован под номером ${data.number}`
          },
          error: (err) => `Ошибка формирования заказа: ${err.toString()}`,
        },
        {
          id: 'formulateOrder',
          style: {
            minWidth: '300px',
            maxWidth: '300px',
          },
          success: {
            duration: 5000,
            //  icon: '🔥',
          },
        }
      )

      setOrderIsFormeting(false)
    } else {
      toast.error(` Не все данные готовы для формирования заказа`, { icon: '🙃' })
    }
  }

  //Очистка
  useEffect(() => {
    return function cleanup() {
      orderStore.setBakeries([])
      orderStore.setbakerieSelect('')
      orderStore.setRoutes([])
      orderStore.setRouteSelect('')
      orderStore.setNomenclature([])
    }
  }, [])

  return (
    <>
      {loadingBakeries ? <Loader /> : <SelectLineConsignees />}
      <VDatePicker />
      {loadingRoute ? <Loader /> : <SelectLineRoute />}
      {loading ? <Loader /> : <NomenclatureTable />}
      {loading || loadingRoute || loadingBakeries || orderStore.dateIsPast() ? (
        ''
      ) : (
        <div className="d-grid gap-2 col-md-6 mx-auto pb-5">
          <button
            className="btn btn-success btn-lg"
            type="button"
            id="button-order"
            onClick={formulateOrder}
            disabled={orderIsFormeting}
          >
            Сформировать
          </button>
        </div>
      )}
    </>
  )
})
