import React from 'react'

// отображение загрузки
export const Loader = () => {
 
  return (
    <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem'}}>
      <div className="spinner-grow text-danger" role="status">
        <span className="visually-hidden">Загрузка...</span>
      </div>
  </div>
  )
}