
import { BrowserRouter } from 'react-router-dom';
import { Toaster} from 'react-hot-toast';
import { useRoutes } from './routes';
import { Navbar } from './components/Navbar/Navbar';
import {Loader} from './components/Loader/Loader'
import authStore from './store/auth.store';
import { observer } from "mobx-react-lite";
// eslint-disable-next-line no-unused-vars
import bootstrap from 'bootstrap'

const App = observer(() => {
  const {ready, isAuthenticated} = authStore
  
  // const isAuthenticated = !!token //приводим token к boolean
  const routes = useRoutes(isAuthenticated)

  //Если еще не успели определить авторизацию
  if (!ready) {
    return <Loader/>
  }

  return (

      <BrowserRouter>
        {isAuthenticated && <Navbar/>//если залогинины то показываем верхнуюю панель
        } 
        <div className="container">        
          {routes}  
          <Toaster
          position="top-center"
          reverseOrder={false}
          
          // containerStyle={{
          //   position: 'absolute',
          // }}
          >
            {/* {(t) => (
              <ToastBar toast={t} >
                {({ icon, message }) => (
                  <>
                    {t.type === 'loading' && (
                      <>{icon}
                      {message}</>                      
                    )}
                    {t.type !== 'loading' && (
                      <div style={{display: 'flex'}} onClick={() => toast.dismiss(t.id)}><div className="">{icon}</div>
                      {message}</div>
                    )}
                  </>
                )}
                 </ToastBar>              
            )} */}
          </Toaster>
        </div>          
      </BrowserRouter>

  );
})

export default App;
