//строка выбора грузополучателя
import React from 'react'
import { observer } from "mobx-react-lite";
import orderStore from "../../store/order.store";

const SelectLineRoute = observer(() => {

  //смена
  const changeRoute = (event) => {
    orderStore.setRouteSelect(event.target.value)
  }

  const arrayIsEmpty = orderStore.routes && orderStore.routes.length === 0

  return (
    <>
     {orderStore.routes && 
      <div className="input-group mb-3">
        <label className="input-group-text" htmlFor="inputGroupSelect01">Рейс</label>         
        <select 
          className="form-select" 
          id="inputGroupSelect01" 
          value={orderStore.routeSelect} 
          onChange={(event) => changeRoute(event)}>
              <option 
                value="" 
                // selected={arrayIsEmpty}
                disabled={!arrayIsEmpty}
              >
                {arrayIsEmpty ? "Данные по маршрутам отсутствуют..." :"Рейс"}
              </option>
              {orderStore.routes.map((element) =>{
              return(
                <option 
                  value={element.code} 
                  key={element.code}>
                    {`${element.route} (${element.deliderTimeFrom} - ${element.deliderTimeTo})`}
                </option>
              )
            })
          }
        </select>
         
      </div>
      }
    </>    
  )
})

export default SelectLineRoute;