//строка выбора грузополучателя
import React from 'react'
import { observer } from "mobx-react-lite";
import orderStore from "../../store/order.store";

const SelectLineConsignees = observer(() => {

  //смена булочной
  const changeBakerieSelect = (event) => {
    orderStore.setbakerieSelect(event.target.value)
  }

  const arrayIsEmpty = orderStore.bakeries && orderStore.bakeries.length === 0
  return (
    <>
     {orderStore.bakeries &&
      <div className="input-group mb-3">
      <label className="input-group-text" htmlFor="inputGroupSelect02">Грузополучатель</label>
        <select 
          className="form-select"           
          id="inputGroupSelect02"
          value={orderStore.bakerieSelect} 
          onChange={(event) => changeBakerieSelect(event)}>
            <option 
                value="" 
                // selected={arrayIsEmpty}
                disabled={!arrayIsEmpty}
            >
              {arrayIsEmpty ? "Данные по торговым точкам отсутствуют..." :"Грузополучатель"}
            </option>
          {orderStore.bakeries.map((element) =>{
              return(
                <option value={element.code} key={element.code}>{element.name}</option>
              )
            })
          }
        </select>
      </div>} 
    </>    
  )
})

export default SelectLineConsignees;