//таблица номенклатуры
import React from 'react'
import { observer } from "mobx-react-lite";
import orderStore from "../../store/order.store";

const NomenclatureTable = observer(() => {
  const pastDate = orderStore.dateIsPast() //если прошедша дата
  const noBarcode = <b className="text-danger">Нет штрихкода</b>
  let noBarcodeCount = 1

  return (
    <>
     {!orderStore.nomenclature ? <b>Данные по номенклатуре отсутствуют...</b> : 
     <div className="table-responsive">
      <table className="table table-sm">
      <thead >
        <tr>
            {/* <th scope="col">№</th> */}
            <th scope="col sm-2">Наименование</th>
            {/* <th scope="col"></th> */}
            <th scope="col"></th> 
            <th scope="col" className="text-center">кол</th>
            <th scope="col"></th>
            <th scope="col"></th> 
            <th scope="col">Ед</th>
            <th scope="col">% воз</th>
        </tr>
      </thead>    
      <tbody>
        {orderStore.nomenclature.map((element, index) =>{
          const kg = element.unit === "кг"
          return(
            <tr key={element.barcode ? element.barcode : `noBarcode${noBarcodeCount++}`}>

              <td>{`${element.nomenclatureName} ${element.nomenclatureCharacteristics}`} {element.barcode ? "" : noBarcode} </td>
              
              <td style={{width: '50px'}}>
                <div className="d-grid gap-2 col-12 mx-0" style={{width: '50px'}}>
                  <button 
                    
                    type="button" 
                    className="btn btn-danger"
                    onClick={() => orderStore.addAmount(-1, element.barcode)}
                    disabled={!element.barcode || pastDate}
                    ><i className="material-icons">-1</i></button>
                </div>  
              </td>
              
              <td className="text-center"><b>{element.quantity}</b></td>

              <td style={{width: '57px'}}>
                <div className="d-grid gap-2 col-12 mx-0" style={{width: '57px'}}>
                  <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={() => orderStore.addAmount(kg ? 0.1 : 1, element.barcode)}
                    disabled={!element.barcode || pastDate}
                    ><i className="material-icons">{kg ? "+0.1" : "+1 "}</i></button>
                </div>              
              </td>

              <td style={{width: '50px'}}>
                <div className="d-grid gap-2 col-12 mx-0" style={{width: '50px'}}>
                  <button 
                    type="button" 
                    className="btn btn-primary"
                    onClick={() => orderStore.addAmount(kg ? 1 : 5, element.barcode)}
                    disabled={!element.barcode || pastDate}
                    ><i className="material-icons">{kg ? "+1" : "+5 "}</i></button>
                </div> 
              </td>
               
              <td>{element.unit}</td>
              <td>{element.returnPercentage}</td>
            </tr>
          )
        })}          
      </tbody>
    </table>
    </div>
    } 
    </>    
  )
})

export default NomenclatureTable;