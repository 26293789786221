import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from './pages/AuthPage/AuthPage'
// import { CreatePage } from './pages/CreatePage/CreatePage'
// import { DetailPage } from './pages/DetailPage'
// import { LinksPage } from './pages/LinksPage'
import { OrderPage } from './pages/OrderPage'
// Если пользователь авторизован, то предоставляем ему доступ к определенным страницам.
export const useRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return (
      // переключатель, который отображает один из Route в зависимости от запрашиваемого адреса
      // если ни один адрес не подошел, то срабатывает Navigate
      <Routes>
        <Route path="/order" element={<OrderPage />} />
        {/* <Route path="/links" exact>
          <LinksPage/>
        </Route>         */}
        {/* <Route path="/create" exact>
          <CreatePage/>
        </Route> */}
        {/* <Route path="/detail/:id">
          <DetailPage/>
        </Route> */}
        <Route path="*" element={<Navigate to="/order" replace />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
