import authStore from '../store/auth.store';

export default class OhkService {  

  static timerUpdateAuthorization;

  static async request(requestParams) {

    try {
      const {token, logout, expiresIn} = authStore
      let {url, method, body, headers = {}} = requestParams
      //если есть время жизни токена, то проверяем что оно не истекло
      if (expiresIn) {

        if (Date.now() > expiresIn) //если срок действия токена вышел
        {
          logout()
          throw new Error('Войдите снова')
        }else if ((Date.now() - 600000) > expiresIn) //если срок действия токена заканчивается раньше чем через 10 минут
        {
          //???сделать обновление токена с сервера
          //???если не удалось обновить токен сделать logout
        }

        headers['Authorization'] = `Bearer ${token}`
      }
    
      //преобразцем объект body в JSON
      if (body) {
        body = JSON.stringify(body)
        // добовляем в заголовоки
        headers['Content-type'] = 'application/json'
      }

      // делаем запрос
      const response = await fetch(url, {method, body, headers})
      
      //если токен не прошел проверку, то делаем logout
      if (response.status === 401) {
        logout()
        throw new Error('Токен не прошел проверку')
      }

      // получаем ответ и преобразуем в объект из JSON
      const data = await response.json()

      if(!response.ok) {
        throw new Error(data.message || 'Что-то пошло не так')
      }

      return data

    } catch (error) {          
      throw error
    }
  }

  static async authorization(body){
    const data = await OhkService.request({
      url: '/api/auth/login',
      method: 'POST', 
      body: body,
      headers: {}
    })

    //записываем данные об id и token пользователя
    authStore.login(data.token, data.userId, data.expiresIn * 1000 + Date.now())

    return data;
  }

  //обновление существующего токена
  static async updateAuthorization(){

    const data = await OhkService.request({
      url: '/api/auth/update',
      method: 'POST', 
      body: null,
      headers: {}
    })

    //записываем данные об id и token пользователя
    authStore.update(data.token, data.expiresIn * 1000 + Date.now())
  }

  static async getBakeries(userId){
    return await OhkService.request({
      url: `/ohkapi/bakeries?user-id=${userId}`,
      method: 'GET', 
      body: null,
      headers: {}})      
  }

  static async getRoutes(bakerie){
    return await OhkService.request({
      url: `/ohkapi/delivery-schedules?code-consignee=${bakerie}`,
      method: 'GET', 
      body: null,
      headers: {}})
  }

  static async getOrder(bakerie, route, date){
    return await OhkService.request({
      url:`/ohkapi/order?consignee=${bakerie}&date=${date}&route=${route}`, 
      method: 'GET', 
      body: null,
      headers: {}
    })
  }

  static async putOrder(data){
    return await OhkService.request({
      url:`/ohkapi/order`, 
      method: 'PUT', 
      body: data,
      headers: {}
    })
  }  
} 