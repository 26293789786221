import { makeAutoObservable } from 'mobx'

class OrderStore {
  newData = true
  date = new Date()
  routes = []
  routeSelect = ''
  nomenclature = []
  bakeries = []
  bakerieSelect = ''

  constructor() {
    makeAutoObservable(this)

    this.date.setDate(this.date.getDate() + 1) //добавляем один день к дате
  }

  setDate(newDate) {
    this.date = newDate
  }

  getDateToString() {
    return this.date.toLocaleDateString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' })
  }

  dateIsPast() {
    return this.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) //если прошедша дата
  }

  setRoutes(newRoutes) {
    this.routes = newRoutes
  }

  setRouteSelect(newRoute) {
    this.routeSelect = newRoute
  }

  setNomenclature(newNomenclature) {
    this.nomenclature = newNomenclature
  }

  setBakeries(newBakeries) {
    this.bakeries = newBakeries
  }

  setbakerieSelect(newBakerieSelect) {
    this.bakerieSelect = newBakerieSelect
    this.routes = []
    this.routeSelect = ''
    this.nomenclature = []
  }

  //добавить количество товара
  addAmount = (quantity, barcode) => {
    this.nomenclature.map((item) => {
      if (item.barcode === barcode) {
        item.quantity += quantity
        item.quantity = parseFloat(item.quantity.toFixed(10)) //округляем до десятичных
        if (item.quantity < 0) {
          item.quantity = 0
        } else if (item.quantity > 100) {
          item.quantity = 100
        }

        return item
      } else {
        return item
      }
    })
  }

  // //Удаление строки номенклатуры
  // const deleteNomenclature = (id) => {
  //   setNomenclature(nomenclature.filter((item) => item.id !== id));
  // };
}

const orderStore = new OrderStore()

export default orderStore
