import { makeAutoObservable } from 'mobx'
import toast from 'react-hot-toast'
import OhkService from '../API/OhkService'

const storageName = 'userData'

class AuthStore {
  token = null
  userId = null
  isAuthenticated = false
  ready = false
  expiresIn = null //время истечения токена
  timerUpdateAuthorization = null

  constructor() {
    makeAutoObservable(this)
    this.loginFromLocalStorage()
  }

  setTimerUpdateAuthorization = (expiresIn) => {
    //делаем автоматический обновление токена после половины времени его истечения
    clearTimeout(this.timerUpdateAuthorization)
    this.timerUpdateAuthorization = setTimeout(() => {
      try {
        OhkService.updateAuthorization()
      } catch (error) {
        toast.error(error.message, { icon: '🙃' })
      }
    }, (expiresIn - Date.now()) * 0.5)
  }

  //вход пользователя в систему
  login = (jwtToken, userId, expiresIn) => {
    this.token = jwtToken
    this.userId = userId
    this.isAuthenticated = true
    this.expiresIn = expiresIn
    //запись в локлаьное хранилище id и token пользователя
    localStorage.setItem(storageName, JSON.stringify({ userId: userId, token: jwtToken, expiresIn }))

    this.setTimerUpdateAuthorization(expiresIn)
  }

  //обновление токена
  update = (jwtToken, expiresIn) => {
    this.token = jwtToken
    this.expiresIn = expiresIn
    //запись в локлаьное хранилище token пользователя
    localStorage.setItem(
      storageName,
      JSON.stringify({ userId: this.userId, token: jwtToken, expiresIn: this.expiresIn })
    )

    this.setTimerUpdateAuthorization(expiresIn)
  }

  //выход пользователя из системы
  logout = () => {
    this.token = null
    this.userId = null
    this.isAuthenticated = false
    this.expiresIn = null
    clearTimeout(this.timerUpdateAuthorization)
    this.timerUpdateAuthorization = null
    localStorage.removeItem(storageName)
  }

  loginFromLocalStorage = () => {
    //Проверяем есть ли данные о пользователе в локльном хранилище
    const data = JSON.parse(localStorage.getItem(storageName))

    //Если есть, то логинимся
    if (data && data.token) {
      this.login(data.token, data.userId, data.expiresIn)
    }

    this.ready = true
  }
}

const authStore = new AuthStore()

export default authStore
